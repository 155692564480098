.FloatDiv_component{
  width: auto;
  height: auto;
  position: relative;
  .FloatDiv_component_aniBox{
    opacity: 0;
    transform: translate(0, 50px);
  }
  .FloatDiv_component_aniClass{
    opacity: 1;
    transform: translate(0, 0)!important;
  }
}
