
.download-right{
  position: absolute;
  top: 50%;
  left: 61%;
  transform: translateY(-50%);
}

.download-left{
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translateY(-50%);
}
