.carousel-box{
  .carousel-desc{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .ant-btn.blue:hover, .ant-btn.blue:focus {
      color: #3284DD;
      border-color: #FFFFFF;
      background: #FFFFFF;
    }
    .ant-btn.green:hover, .ant-btn.green:focus {
      color: #44AA72;
      border-color: #FFFFFF;
      background: #FFFFFF;
    }
  }
  .slick-dots-bottom{
    bottom: auto;
  }
  .slick-dots{
    display: inline-block !important;
    justify-content: center;
    margin-right: 200rem;
    margin-left: 200rem;
    top: 63%;
    li{
      position: relative;
      display: inline-block;
      flex: 0 1 auto;
      box-sizing: content-box;
      width: 15rem;
      height: 15rem;
      margin: 0 2px;
      margin-right: 30rem;
      margin-left: 3px;
      padding: 0;
      text-align: center;
      text-indent: -999px;
      vertical-align: top;
      transition: all 0.5s;
      button{
        display: block;
        width: 100%;
        height: 15rem;
        padding: 0;
        color: transparent;
        font-size: 0;
        background: #fff;
        border: 0;
        border-radius: 1px;
        outline: none;
        cursor: pointer;
        opacity: 0.3;
        transition: all 0.5s;
        border-radius: 15rem;
      }
    }
    li.slick-active {
      width: 15rem;
    }
  }
}

