.product{
  padding-top: 90rem;
}
.product-feat{
  position: absolute;
  top: -90rem;
  width: calc(100% - 320rem);
  left: 160rem;
  border-radius: 10rem;
  box-shadow: 0rem 0rem 20rem 0rem rgba(0,0,0,0.2);
  background-color: #FFFFFF !important;
  box-sizing: border-box;
  overflow: hidden;
}
.product-feat>div{
  padding: 32rem 0;
}
.product-feat-item{
  background-color: #E8F5FF !important;
}

.procard-icon:hover{
  transform: scale(1.1);
}

.product-cont>div+div+div{
  border-top: 1px dashed #979797;
}
.product-cont>div:nth-child(odd){
  border-right: 1px dashed #979797;
  img{
    padding-right: 200rem;
    box-sizing: border-box;
  }
}
.product-cont>div:nth-child(even){
  padding-left: 200rem;
  box-sizing: border-box;
}


.card{
  background-color: #FFFFFF !important;
  box-shadow: 0rem 0rem 20rem 0rem rgba(0,0,0,0.1);
  border-radius: 8rem;
  padding: 35rem 32rem;
}
