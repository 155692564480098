.ant-layout{
  .ant-layout-footer{
    padding: 0rem;
    background: #f7f9fe;
    .layout-footer{
      .footer-desc{
        text-align: center;
        padding: 57rem 0 48rem 0;
        height: 300rem;
        background: url('../../assets/footer.png') no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        .footer-btn{
          width: 240rem;
          height: 60rem;
          background-image: linear-gradient(135deg, #2872DD 0%, #4FACEE 100%);
          border-radius: 100rem;
          font-family: PingFangSC-Medium;
          font-size: 24rem;
          color: #F3F7FE;
          letter-spacing: 0;
          font-weight: 500;
          margin-top: 26rem;
        }
        .footer-btn:hover{
          background-image: linear-gradient(135deg, #4FACEE 0%, #2872DD 100%);
        }
      }
      .footer-message{
        padding: 58rem 200rem 0 200rem;
        div{
          color: #626468;
          letter-spacing: 0;
          font-weight: 400;
        }
        .footer-copyright{
          padding: 20rem 0;
          border-top: 1px solid rgba(48,49,51,0.2);
          margin-top: 40rem;
        }
        .footer-link:hover,.footer-link:focus{
          color: #3284DD;
        }
      }

    }
  }
}

