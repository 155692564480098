body {
  margin: 0;
  font-family: PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
}

#root{
  background-color: #ffffff !important;
  .ant-layout{
    background-color: #FFFFFF !important;
  }
}

.bd1F{
  border: 1px solid #FFFFFF;
}
.bd1P{
  border: 1px solid #23a0f4;
}
.bd4F{
  border: 4rem solid #FFFFFF;
}
.bd4B{
  border: 4rem solid #23a0f4;
}
.bd10F{
  border: 10rem solid #FFFFFF;
}
.bdb1E{
  border-bottom: 1px solid #EBEEF5;
}
.bdb1F{
  border-bottom: 1px solid #FFFFFF;
}
.bd-color{
  border-color: #FFFFFF !important;
}

.back-white{
  background: #FFFFFF !important;
  background-color: #FFFFFF !important;
}
.back-white-opacity16{
  background: rgba(255,255,255,0.16) !important;
}
.back-white-opacity20{
  background: rgba(255,255,255,0.2)
}
.back-active{
  background: rgba(56,183,247,0.1);
}
.back-footer-opacity80{
  background: rgba(48,49,51,0.8);
}
.back-border{
  background: #23a0f4;
}

.bsbx{
  box-sizing: border-box;
}
.bscx{
  box-sizing: content-box;
}

.bs{
  box-shadow: 0rem 0rem 6rem 0rem rgba(0,0,0,0.15);
}
.bsdown{
  box-shadow: 0rem 2rem 10rem 0rem rgba(164,164,164,0.5);
}
.bsvideo{
  box-shadow: 0rem 0rem 20rem 0rem rgba(0,0,0,0.2);
}


.bdrs8{
  border-radius: 8rem;
}
.bdrs10{
  border-radius: 10rem;
}
.bdrs20{
  border-radius: 20rem;
}
.bdrs30{
  border-radius: 30rem;
}
.bdrs60{
  border-radius: 60rem;
}
.bdrs100{
  border-radius: 100rem;
}

.font-semibold{
  font-family: PingFangSC-Semibold;
}
.font-medium{
  font-family: PingFangSC-Medium;
}
.font-regular{
  font-family: PingFangSC-Regular;
}
.font-bold{
  font-family: SourceHanSansCN-Bold;
}

.fs13{
  font-size: 13rem;
}
.fs14{
  font-size: 14rem;
}
.fs16{
  font-size: 16rem !important;
}
.fs18{
  font-size: 18rem;
}
.fs20{
  font-size: 20rem;
}
.fs24{
  font-size: 24rem !important;
}
.fs26{
  font-size: 26rem;
}
.fs28{
  font-size: 28rem;
}
.fs30{
  font-size: 30rem;
}
.fs32{
  font-size: 32rem;
}
.fs36{
  font-size: 36rem !important;
}
.fs38{
  font-size: 38rem;
}
.fs40{
  font-size: 40rem;
}
.fs48{
  font-size: 48rem;
}
.fs56{
  font-size: 56rem;
}
.fs68{
  font-size: 68rem !important;
}

.fw400{
  font-weight: 400;
}
.fw500{
  font-weight: 500;
}
.fw700{
  font-weight: 700;
}

.h8{
  height: 8rem;
}
.h10{
  height: 10rem;
}
.h15{
  height: 15rem;
}
.h36{
  height: 36rem;
}
.h40{
  height: 40rem;
}
.h44{
  height: 44rem;
}
.h50{
  height: 50rem;
}
.h58{
  height: 58rem;
}
.w60{
  height: 60rem;
}
.h67{
  height: 67rem;
}
.h71{
  height: 71rem;
}
.h72{
  height: 72rem;
}
.h80{
  height: 80rem;
}
.h88{
  height: 88rem;
}
.h100{
  height: 100rem !important;
}
.h110{
  height: 110rem;
}
.h120{
  height: 120rem;
}
.h130{
  height: 130rem;
}
.h140{
  height: 140rem;
}
.h158{
  height: 158rem;
}
.h180{
  height: 180rem;
}
.h192{
  height: 192rem;
}
.h1600{
  height: 1600rem;
}
.h1780{
  height: 1780rem;
}
.h-auto{
  height: auto !important;
}
.h-full{
  height: 100%;
}

.ib{
  display: inline-block;
}

.left0{
  left: 0;
}
.left200{
  left: 200rem;
}
.left303{
  left: 303rem;
}

.lh16{
  line-height: 16rem !important;
}
.lh19{
  line-height: 19rem;
}
.lh24{
  line-height: 24rem;
}
.lh26{
  line-height: 26rem;
}
.lh28{
  line-height: 28rem;
}
.lh30{
  line-height: 30rem;
}
.lh32{
  line-height: 32rem;
}
.lh36{
  line-height: 36rem;
}
.lh40{
  line-height: 40rem;
}
.lh44{
  line-height: 44rem;
}
.lh48{
  line-height: 48rem;
}
.lh56{
  line-height: 56rem;
}
.lh60{
  line-height: 60rem;
}
.lh72{
  line-height: 72rem;
}
.lh80{
  line-height: 80rem;
}
.lh140{
  line-height: 140rem;
}

.mt-4{
  margin-top: -4rem;
}
.mt4{
  margin-top: 4rem;
}
.mt-5{
  margin-top: -5rem;
}
.mr6{
  margin-right: 6rem;
}
.mr8{
  margin-right: 8rem;
}
.ml9{
  margin-left: 9rem;
}
.mt10{
  margin-top: 10rem;
}
.mb10{
  margin-bottom: 10rem;
}
.mr12{
  margin-right: 12rem;
}
.mt13{
  margin-top: 13rem;
}
.mt14{
  margin-top: 14rem;
}
.mr14{
  margin-right: 14rem;
}
.mr16{
  margin-right: 16rem;
}
.mb16{
  margin-bottom: 16rem;
}
.mt20{
  margin-top: 20rem;
}
.mr20{
  margin-right: 20px;
}
.mt22{
  margin-top: 22rem;
}
.mt24{
  margin-top: 24rem;
}
.mb24{
  margin-bottom: 24rem;
}
.ml30{
  margin-left: 30rem;
}
.mr30{
  margin-right: 30rem;
}
.mr32{
  margin-right: 32rem;
}
.ml32{
  margin-left: 32rem;
}
.mb32{
  margin-bottom: 32rem;
}
.ml40{
  margin-left: 40rem;
}
.mb40{
  margin-bottom: 40rem;
}
.mt56{
  margin-top: 56rem;
}
.ml58{
  margin-left: 58rem;
}
.mb60{
  margin-bottom: 60rem;
}
.mt80{
  margin-top: 80rem;
}
.mr80{
  margin-right: 80rem;
}
.mt88{
  margin-top: 88rem;
}
.mt120{
  margin-top: 120rem;
}
.mt136{
  margin-top: 136rem;
}
.mb136{
  margin-bottom: 136rem;
}
.mt160{
  margin-top: 160rem;
}
.mb160{
  margin-bottom: 160rem;
}
.mt-282{
  margin-top: -282rem;
}

.opacity23{
  opacity: 0.23;
}
.opacity40{
  opacity: 0.4;
}
.opacity60{
  opacity: 0.6;
}
.opacity100{
  opacity: 1;
}

.rem10{
  padding: 10rem;
}
.p2{
  padding: 2px;
}
.pt6{
  padding-top: 6rem;
}
.pb6{
  padding-bottom: 6rem;
}
.px10{
  padding: 10rem;
}
.pt10{
  padding-top: 10rem;
}
.pt11{
  padding-top: 11rem;
}
.pb11{
  padding-bottom: 11rem;
}
.pb14{
  padding-bottom: 14rem;
}
.pb16{
  padding-bottom: 16rem;
}
.px20{
  padding: 20rem;
}
.pt20{
  padding-top: 20rem;
}
.pl20{
  padding-left: 20rem;
}
.pr20{
  padding-right: 20rem;
}
.pb23{
  padding-bottom: 23rem;
}
.pt24{
  padding-top: 24rem;
}
.pb24{
  padding-bottom: 24rem;
}
.pl24{
  padding-left: 24rem;
}
.pr24{
  padding-right: 24rem;
}
.pr28{
  padding-right: 28rem;
}
.pt32{
  padding-top: 32rem;
}
.pb32{
  padding-bottom: 32rem;
}
.pl37{
  padding-left: 37rem;
}
.pr37{
  padding-right: 37rem;
}
.pt40{
  padding-top: 40rem;
}
.pl60{
  padding-left: 60rem;
}
.pr60{
  padding-right: 60rem;
}
.pt60{
  padding-top: 60rem;
}
.pb60{
  padding-bottom: 60rem;
}
.pr75{
  padding-right: 75rem;
}
.pl75{
  padding-left: 75rem;
}
.pt80{
  padding-top: 80rem;
}
.pb80{
  padding-bottom: 80rem;
}
.pb103{
  padding-bottom: 103rem;
}
.pt110{
  padding-top: 110rem;
}
.pt120{
  padding-top: 120rem;
}
.pr140{
  padding-right: 140rem;
}
.pl140{
  padding-left: 140rem;
}
.pr160{
  padding-right: 160rem;
}
.pl160{
  padding-left: 160rem;
}
.pb160{
  padding-bottom: 160rem;
}
.pl200{
  padding-left: 200rem;
}
.pr200{
  padding-right: 200rem;
}
.pr260{
  padding-right: 260rem;
}
.pl260{
  padding-left: 260rem;
}

.pointer{
  cursor: pointer;
}

.default{
  cursor: default;
}

.pr{
  position: relative;
}
.ps{
  position: absolute;
}
.pf{
  position: fixed;
}

.white{
  color: #FFFFFF !important;
}

.text-color{
  color: #626468;
}
.text-color-footer{
  color: #303133;
}
.text-color-white{
  color: #FFFFFF
}
.text-color-download{
  color: #3284DD !important;
}
.text-color-student{
  color: #44AA72 !important;
}

.text-color-pc{
  color: #FFFFFF !important;
  background: linear-gradient(360deg, #F16434 0%, #FFB371 100%) !important;
  border: 0px solid transparent !important;
}

.text-color-pc:hover{
  color: #FFFFFF !important;
  background: linear-gradient(360deg, #FF7E52 0%, #FFD38E 100%) !important;
  border: 0px solid transparent !important;
}

.text-indent{
  text-indent: 2em;
}

.tl{
  text-align: left;
}
.tc{
  text-align: center;
}
.tr{
  text-align: right;
}

.top0{
  top: 0;
}

.vam{
  vertical-align: middle;
}
.vat{
  vertical-align: top;
}

.w-full{
  width: 100%;
}
.w-30{
  width: 30%;
}
.w-50{
  width: 50%;
}
.w8{
  width: 8rem;
}
.w10{
  width: 10rem;
}
.w15{
  width: 15rem;
}
.w58{
  width: 58rem;
}
.w60{
  width: 60rem;
}
.w72{
  width: 72rem;
}
.w80{
  width: 80rem;
}
.w88{
  width: 88rem;
}
.w100{
  width: 100rem !important;
}
.w110{
  width: 110rem;
}
.w120{
  width: 120rem;
}
.w130{
  width: 130rem;
}
.w140{
  width: 140rem;
}
.w161{
  width: 161rem;
}
.w180{
  width: 180rem;
}
.w182{
  width: 182rem;
}
.w186{
  width: 186rem;
}
.w210{
  width: 210rem;
}
.w235{
  width: 235rem;
}
.w274{
  width: 274rem;
}
.w278{
  width: 278rem;
}
.w420{
  width: 420rem;
}

.wsno{
  white-space: nowrap;
}

.overflow-hidden{
  overflow: hidden;
}
.overflow-auto{
  overflow: auto;
}

code {
  font-family: PingFangSC-Regular;
}

@media screen and (min-width: 0rem) and (max-width: 1536rem){
  body {
    min-width: 1536rem;
    overflow-x: auto;
  }
}

iframe{
  display: none;
}

.flex{
  display: flex;
}
.flex1{
  flex: 1;
}
.flex-col{
  flex-direction: column;
}
.flex-between{
  justify-content: space-between;
}

.enter{
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

//两行
.twoLine{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ant-layout{
  background-color: #FFFFFF !important;
}
.ant-dropdown{
  top: 80rem !important;
}
.ant-image{
  vertical-align:top;
}
.ant-back-top{
  right: 52rem;
  bottom: 42rem;
}
.ant-modal-close-x{
  width: 58rem;
  height: 58rem;
  line-height: 58rem;
}
.ant-btn:hover, .ant-btn:focus {
  color: #ffffff;
  border-color: unset;
  background: unset;
}
.manual-btn:hover, .manual-btn:focus {
  color: #3284DD;
}
.concard-btn:hover, .concard-btn:focus {
  opacity: 0.78;
}
.video-btn:hover, .video-btn:focus{
  background-image: linear-gradient(135deg, #2872DD 0%, #4FACEE 100%);
  box-shadow: 0px 2px 8px 0px rgba(180,216,255,1);
}
.ant-slider{
  height: 8rem;
  margin: 0;
  padding: 0;
}
.policy{
  .rich-text-fbfcfe{
    p:first-child{
      font-family: PingFangSC-Medium !important;
      line-height: 40rem !important;
      font-size: 48rem !important;
      font-weight: 500 !important;
      color: #303133 !important;
      padding-bottom: 60rem !important;
      text-align: center !important;
      border-bottom: 1px solid #EBEEF5 !important;
      span{
        font-family: PingFangSC-Medium !important;
        line-height: 40rem !important;
        font-size: 48rem !important;
        font-weight: 500 !important;
        color: #303133 !important;
      }
    }
  }
  p{
    font-family: PingFangSC-Regular !important;
    line-height: 48rem !important;
    font-size: 24rem !important;
    font-weight: 400 !important;
    color: #626468 !important;
    span{
      font-family: PingFangSC-Regular !important;
      line-height: 48rem !important;
      font-size: 24rem !important;
      font-weight: 400 !important;
      color: #626468 !important;
    }
  }
}
