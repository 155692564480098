.educator{
  width: 100%;
  // height: 909rem;
  background: url('../../../assets/home/education-bg.png') no-repeat;
  background-size: 100% 100%;
  .educator-active{
    width: 814rem;
    height: 578rem;
    background: url('../../../assets/home/education-active.png') no-repeat;
    background-size: 100% 100%;
  }
  .educator-li{
    display: inline-block;
    position: absolute;
    width: 60rem;
    height: 60rem;
    img{
      box-shadow: 0 5px 14px 0 #C9D9E7;
    }
  }
  .educator-li.educator-li1{
    top: 32rem;
    left: 100rem;
  }
  .educator-li.educator-li2{
    top: 25rem;
    left: 213rem;
  }
  .educator-li.educator-li3{
    top: 26rem;
    left: 382rem;
  }
  .educator-li.educator-li4{
    top: 24rem;
    right: 302rem;
  }
  .educator-li.educator-li5{
    top: 26rem;
    right: 66rem;
  }
  .educator-li.educator-li6{
    top: 176rem;
    left: 45rem;
  }
  .educator-li.educator-li7{
    top: 178rem;
    left: 140rem;
  }
  .educator-li.educator-li8{
    top: 182rem;
    left: 286rem;
  }
  .educator-li.educator-li9{
    top: 178rem;
    right: 420rem;
  }
  .educator-li.educator-li10{
    top: 180rem;
    right: 184rem;
  }
  .educator-li.educator-li11{
    top: 182rem;
    right: -40rem;
  }
  .educator-li.educator-li12{
    top: 310rem;
    left: 99rem;
  }
  .educator-li.educator-li13{
    top: 324rem;
    left: 220rem;
  }
  .educator-li.educator-li14{
    top: 326rem;
    left: 380rem;
  }
  .educator-li.educator-li15{
    top: 328rem;
    left: 600rem;
  }
  .educator-li.educator-li16{
    top: 326rem;
    right: 64rem;
  }
  .educator-li.educator-li17{
    top: 454rem;
    left: 189rem;
  }
  .educator-li.educator-li18{
    top: 468rem;
    left: 327rem;
  }
  .educator-li.educator-li19{
    top: 474rem;
    left: 510rem;
  }
  .educator-li.educator-li20{
    top: 474rem;
    right: 170rem;
  }
  .educator-li.educator-li21{
    top: 580rem;
    left: 305rem;
  }
  .educator-li.educator-li22{
    top: 594rem;
    left: 460rem;
  }
  .educator-li.educator-li23{
    top: 600rem;
    right: 256rem;
  }
}

.educator-scale{
  transform: scale(1.67);
  transform-origin: center;
}
