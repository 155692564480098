.ant-layout{
  .ant-layout-header{
    width: 100%;
    height: auto;
    padding: 0rem;
    color: transparent;
    line-height: auto;
    background: transparent;
    .layout-header{
      position: absolute;
      width: 100%;
      height: 80rem;
      padding: 0 60rem 0 107rem;
      // background: rgba(255,255,255,0.2);
      z-index: 10;
      line-height: 80rem;
      .layout-logo{
        margin-right: 147rem;
      }
    }
    .layout-menu{
      display: inline-block;
    }
    .enter-plat:hover, .enter-plat:focus{
      background: #3284DD !important;
      color: #FFFFFF !important;
    }
    .ant-affix{
      .layout-header{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
        .ant-dropdown-trigger{
          color: #626468;
        }
        .enter-plat{
          background: transparent;
          color: #3284DD;
          border: 1px solid #3284DD;
        }
        .enter-plat:hover, .enter-plat:focus{
          background: #3284DD !important;
          color: #FFFFFF !important;
        }
      }
    }
  }
}
